import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerTeam from '../components/BannerTeam'
import connectTimeline from '../assets/images/connect/connectTimeline.png'

export default function Build() {
  const style = {
    width: '90vw',
    height: 'auto',
    // borderRadius: '10px',
    // borderStyle: 'solid',
    // borderColor: 'white',
    // borderWidth: '8px'
    boxShadow: '#131520 0 0 20px 10px',
  }

  return (
    <div>
      <Layout>
        <Helmet>
          <title>Krypton Warriors</title>
          <meta name="description" content="Landing Page" />
          <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "53ca0e93ef80463192e735fa104fef39"}'></script>
        </Helmet>

        <BannerTeam />
        <div>
          <div id="main">
            <section id="one">
              <div className="inner">
                <header className="major">
                  <h2>About</h2>
                </header>
                <p>
                  Our team actively looks to connect the dots between our
                  community, FIRST, and the diversity of the engineering world.
                  We understand that our local STEM community plays an essential
                  role in our success. We zealously seek for help in
                  understanding various engineering principles and exploring the
                  opportunities available in science, technology, and
                  engineering fields. In addition, we engage with the
                  engineering community to help them understand FIRST and the
                  FIRST Tech Challenge. We recognize that the more individuals
                  or companies we connect to, the more beneficial it is for us.
                </p>
              </div>
              <div style={{ display: 'grid', placeItems: 'center' }}>
                <img
                  src={connectTimeline}
                  style={style}
                  alt="connectTimeline"
                ></img>
                <br></br>
                <br></br>
              </div>
            </section>
          </div>
        </div>
      </Layout>
    </div>
  )
}
